import React, { useState, useEffect } from 'react';
import '../css/library.css'; // Import the CSS file for styling
import Scan from "./scan";

function Library() {
  const [books, setBooks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [booksPerPage] = useState(102); // Set the number of books per page
  const [selectedBook, setSelectedBook] = useState(null);
  const [collections, setCollections] = useState([]);
  const [selectedCollectionId, setSelectedCollectionId] = useState(null);
  const [sortBy, setSortBy] = useState('added'); // Set default value to 'title'
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false); // Add state for search button text
  const [addToCollectionMessage, setAddToCollectionMessage] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [newUploadedImage, setNewUploadedImage] = useState('');
  const [readStateFilter, setReadStateFilter] = useState('');
  const [ownershipStateFilter, setOwnershipStateFilter] = useState('');
  const [ownerStateFilter, setOwnerStateFilter] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [format, setFormat] = useState(false);
  const [special, setSpecial] = useState(false);
  const [loading, setLoading] = useState(false); // Introduce loading state
  const [showShareModal, setShowShareModal] = useState(false); // State for showing/hiding share modal
  const [sharedWith, setSharedWith] = useState([]);
  const [newUser, setNewUser] = useState('');
  const [sharedWithInitialized, setSharedWithInitialized] = useState(false);
  const [deletedBook, setDeletedBook] = useState(false);
  const [isCusomImageToggled, setIsCusomImageToggled] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [scanButtonText, setScanButtonText] = useState(<i className="fas fa-book-medical"></i>);
  const [bookCollections, setBookCollections] = useState([]);

  const isMobileDevice = () => {
    return window.innerWidth <= 768; // You can adjust the width as per your requirement
  }
  const [isMobile, setIsMobile] = useState(isMobileDevice());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileDevice());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  }
  
  // Pagination Logic
  const indexOfLastBook = currentPage * booksPerPage;
  const indexOfFirstBook = indexOfLastBook - booksPerPage;
  const currentBooks = books.slice(indexOfFirstBook, indexOfLastBook);
  
  // Pagination Logic
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(books.length / booksPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = () => {
    const maxVisiblePages = 3;
    const totalPages = pageNumbers.length;

    if (totalPages <= maxVisiblePages) {
      return pageNumbers.map(number => (
        <button
          key={number}
          onClick={() => setCurrentPage(number)}
          className={currentPage === number ? "active" : ""}
        >
          {number}
        </button>
      ));
    } else {
      let leftSide = [];
      let rightSide = [];

      if (currentPage <= maxVisiblePages - 1) {
        leftSide = pageNumbers.slice(0, maxVisiblePages);
        rightSide = ["right"];
      } else if (currentPage >= totalPages - maxVisiblePages + 1) {
        leftSide = ["left"];
        rightSide = pageNumbers.slice(totalPages - maxVisiblePages, totalPages);
      } else {
        const middleIndex = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 0);
        leftSide = ["left"];
        rightSide = ["right"];

        for (let i = middleIndex; i < middleIndex + maxVisiblePages; i++) {
          leftSide.push(i);
        }
      }

      return (
        <>
          {leftSide.map((number, index) => (
            <button
              key={index}
              onClick={() => {
                if (number !== "left" && number !== "right") {
                  setCurrentPage(number);
                } else if (number === "left") {
                  setCurrentPage(currentPage - 1);
                }
                window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page

              }}
              className={currentPage === number ? "active" : ""}
            >
              {number !== "left" && number !== "right" ? number : "←"}
            </button>
          ))}
          {rightSide.map((number, index) => (
            <button
              key={index + leftSide.length}
              onClick={() => {
                if (number !== "left" && number !== "right") {
                  setCurrentPage(number);
                } else if (number === "right") {
                  setCurrentPage(currentPage + 1);
                }
                window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
              }}
              className={currentPage === number ? "active" : ""}
            >
              {number !== "left" && number !== "right" ? number : "→"}
            </button>
          ))}
        </>
      );
    }
  };
      
  const getCollections = async () => {
    setLoading(true);
    const user = localStorage.getItem('loggedInUsername');
    const token = localStorage.getItem('accessToken');

    try {
      const response = await fetch("/library/collections", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ user, sortBy }),
      });
      if (response.ok) {
        const data = await response.json();
        setCollections(data);
      } else {
        console.error('Failed to fetch collections');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getCollections();
    if (!sharedWithInitialized) {
      getSharedWith(); // Assuming this function is defined elsewhere
      setSharedWithInitialized(true);
    }
  }, []);

  const getSharedWith = async () => {
    const user = localStorage.getItem('loggedInUsername');
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch("/auth/sharedwith", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ user }),
      });
      if (response.ok) {
        const data = await response.json();
        setSharedWith(data);
      } else {
        console.error('Failed to fetch shared with');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const shareBooks = async () => {
    const user = localStorage.getItem('loggedInUsername');
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch("/auth/share", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ user, newUser }),
      });
      if (response.ok) {
        // Optionally, you may want to update the UI or perform any necessary actions
        getSharedWith();
        setNewUser(''); // Clear input field after sharing
      } else {
        console.error('Failed to share books');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const unshareUser = async (unshareUser) => {
    const user = localStorage.getItem('loggedInUsername');
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch("/auth/unshare", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ user, unshareUser }),
      });
      if (response.ok) {
        getSharedWith();
        setNewUser('');
      } else {
        console.error('Failed to share books');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCollectionSelect = (e) => {
    setSelectedCollectionId(e.target.value);
  };

  const handleAddToCollection = async () => {
    const collectionId = selectedCollectionId || collections[0].id;
    const token = localStorage.getItem('accessToken');
    const user = localStorage.getItem('loggedInUsername');

    if (!selectedBook) return;
    try {
      const response = await fetch("/library/collection/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
          user,
          collectionId,
          bookId: selectedBook.id,
        }),
      });
      if (response.ok) {
        setAddToCollectionMessage('Book added to collection successfully.');
        // Optionally, you may want to update the UI or perform any necessary actions
      } else {
        setAddToCollectionMessage('Failed to add book to collection.');
        console.error('Failed to add book to collection');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const READ_STATE = {
    0: "READ",
    1: "UNREAD",
    2: "READING"
  };
  
  const OWNERSHIP_STATE = {
    0: "OWNED",
    1: "ORDERED",
    2: "WANTED"
  };

  const getBackgroundColor = (value) => {
    switch(value) {
      case READ_STATE[0]:
        return '#459948';
      case READ_STATE[1]:
        return '#d83f92';
      case OWNERSHIP_STATE[0]:
        return '#d3d3e5';
      case OWNERSHIP_STATE[1]:
        return '#e398d0';
      case OWNERSHIP_STATE[2]:
        return '#e6e684';
      default:
        return '#6fc3e5';
    }
  };

  const getAllBooks = async () => {
    setLoading(true);
    const user = localStorage.getItem('loggedInUsername');
    const token = localStorage.getItem('accessToken');
    try {
      const response = await fetch("/library/books", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ user, sortBy, readState: readStateFilter, ownershipState: ownershipStateFilter, ownedState: ownerStateFilter, sharedWith: sharedWith }),
      });
      if (response.ok) {
        const data = await response.json();
        setBooks(data);
      } else {
        console.error('Login or registration failed');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (sharedWithInitialized && sharedWith) {
      getAllBooks();
    }
  }, [sortBy, readStateFilter, ownershipStateFilter, ownerStateFilter, sharedWith]);

  const handleSearch = async () => {
    const user = localStorage.getItem('loggedInUsername');
    const token = localStorage.getItem('accessToken');

    try {
      const response = await fetch("/library/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ searchTerm, user }),
      });
      if (response.ok) {
        const data = await response.json();
        setBooks(data);
      } else {
        console.error('Search failed');
      }
       
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleClearSearch = async() => {
    setIsSearching(false);
    setSearchTerm('');
    getAllBooks()
  }

  // Function to clear filters
  const clearFilters = () => {
    setOwnershipStateFilter('');
    setReadStateFilter('');
    setOwnerStateFilter('');
    setSortBy('added');
  };

  const closeFilters = () => {
    setShowFilters(false);
  };
  
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      document.getElementById('searchButton').click();
    }
  };

  const getBookCollections = async (book) => {
    const token = localStorage.getItem('accessToken');
    const user = localStorage.getItem('loggedInUsername');

    try {
      const response = await fetch("/library/book/collections", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
          user: user,
          book: book.isbn
        })
      });
      if (response.ok) {
        const data = await response.json()
        setBookCollections(data.collection_names)
      } else {
        console.error('Failed to get book collections');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const handleBookClick = (book) => {
    getBookCollections(book);
    setSelectedBook(book);
    setIsCusomImageToggled(book.use_custom_image);
  };

  const handleCloseBookDetails = () => {
    setAddToCollectionMessage('');
    setSelectedBook(null);
  };

  const handleReadStateChange = (e) => {
    const newReadState = e.target.value;
    setSelectedBook(prevBook => ({
      ...prevBook,
      read: newReadState
    }));
    updateBook({ ...selectedBook, read: newReadState });
  };
  
  const handleOwnershipStateChange = (e) => {
    const newOwnershipState = e.target.value;
    setSelectedBook(prevBook => ({
      ...prevBook,
      ownership: newOwnershipState
    }));
    updateBook({ ...selectedBook, ownership: newOwnershipState });
  };

  const updateBook = async (updatedBook) => {
    const token = localStorage.getItem('accessToken');
    const user = localStorage.getItem('loggedInUsername');

    try {
      const response = await fetch("/library/modify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
          user: user,
          isbn: updatedBook.isbn, 
          readState: updatedBook.read,
          ownershipState: updatedBook.ownership,
          rating: updatedBook.rating, // include rating in the request body
          format: updatedBook.format,
          special: updatedBook.special
        }),
      });
      if (response.ok) {
        if(searchTerm.length > 0){
          handleSearch();
        }else{
          getAllBooks();
        }
      } else {
        console.error('Failed to update book');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };



  const convertTimestampToDate = (timestamp) => {
    // Multiply by 1000 if the timestamp is in seconds
    const date = new Date(timestamp * 1000);
    // Format the date as needed (e.g., "MM/DD/YYYY hh:mm:ss")
    const formattedDate = date.toLocaleString(); // Adjust the formatting as per your requirement
    return formattedDate;
  };

  // Function to open the edit modal
  const handleEditClick = () => {
    setIsEditModalOpen(true);
    setNewTitle(selectedBook.title); // Set default title value
    setNewDescription(selectedBook.description); // Set default description value
    setIsCusomImageToggled(selectedBook.use_custom_image);
  };
  
  const handleFormatClick = (book) => {
    const newFormat = !book.format;
    setFormat(newFormat);
    setSelectedBook(prevBook => ({
      ...prevBook,
      format: newFormat
    }));
    updateBook({ ...selectedBook, format: newFormat });
  };

  const handleSpecialClick = (book) => {
    const newSpecial = !book.special;
    setSpecial(newSpecial);
    setSelectedBook(prevBook => ({
      ...prevBook,
      special: newSpecial
    }));
    updateBook({ ...selectedBook, special: newSpecial });
  }

  // Function to close the edit modal
  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    
    // Create a new image element
    const img = new Image();
  
    // Define a function to handle loading of the image
    img.onload = function () {
      // Create a canvas element
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
  
      // Calculate new dimensions
      let width = img.width;
      let height = img.height;
      const aspectRatio = width / height;
      const targetWidth = 128;
      const targetHeight = 196;
  
      if (width > height) {
        width = targetWidth;
        height = width / aspectRatio;
      } else {
        height = targetHeight;
        width = height * aspectRatio;
      }
  
      // Set canvas dimensions
      canvas.width = targetWidth;
      canvas.height = targetHeight;
  
      // Draw image on canvas
      ctx.drawImage(img, 0, 0, width, height);
  
      // Get base64 representation of the resized image
      const resizedImageData = canvas.toDataURL('image/jpeg');
  
      // Update state with the base64-encoded image data
      setNewUploadedImage(resizedImageData);
    };
  
    // Read the file as a data URL
    img.src = URL.createObjectURL(file);
  };
  
  const handleIsCustomImageToggled = () => {
    setIsCusomImageToggled(!isCusomImageToggled);
  }
  

  // Function to handle saving the new title
  const handleSaveTitle = async () => {
    const token = localStorage.getItem('accessToken');
    const user = localStorage.getItem('loggedInUsername');

    try {
      const response = await fetch("/library/book/modify-title", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
          user: user,
          bookId: selectedBook.id,
          newTitle: newTitle,
          newDescription: newDescription,
          useCustomImage: isCusomImageToggled,
          newUploadedImage: newUploadedImage
        }),
      });
      if (response.ok) {
        // Close the modal and update book title

        handleCloseEditModal();
        setSelectedBook(prevBook => ({
          ...prevBook,
          title: newTitle,
          description: newDescription,
          use_custom_image: isCusomImageToggled,
          uploaded_image_b64: newUploadedImage
        }));

        if(searchTerm.length > 0){
          handleSearch();
        }else{
          getAllBooks();
        }
        
      } else {
        console.error('Failed to modify title');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const deleteBook = async (bookId) => {
    setSelectedBook(null);
    const token = localStorage.getItem('accessToken');
    const user = localStorage.getItem('loggedInUsername');

    try {
      const response = await fetch("/library/delete", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ bookId: bookId, user: user }),
      });
      if (response.ok) {
        // Collection deleted successfully, update UI or perform any necessary actions
        if(searchTerm.length > 0){
          handleSearch();
        }else{
          getAllBooks();
        }
      } else {
        console.error('Failed to delete collection');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const toggleIsScanning = () => {
    if(!isScanning){
      setScanButtonText(<i className="fas fa-book"></i>);
    }
    else{
      setScanButtonText(<i class="fa-solid fa-book-medical"></i>);
      getAllBooks();
    }
    setIsScanning(!isScanning);
  }
  
  return (
    <div className='lib-page'>
      <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between',  paddingTop: '10px' }}>
        <div style={{ flex: '1', marginLeft: '15px' }}>
          <input
            id="search-box"
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown} // Add key press event listener
            className='search-box'
          />
          <button id="searchButton" className="search-button-3" onClick={() => { setCurrentPage(1); setIsSearching(true); handleSearch(); }}><i className="fas fa-search"></i></button>
          <button className='search-button-3' onClick={toggleFilters}>{showFilters ? <i class="fa-solid fa-filter-circle-xmark"></i> : <i class="fa-solid fa-filter"></i>}</button>
          {!isMobile && isSearching && (<button id="clearSearchButton" className="search-button" onClick={() => { handleClearSearch(); }}>Clear Search</button>)}
          <button className="search-button-3" onClick={() => toggleIsScanning()}>{scanButtonText}</button>
        </div>
      </div>

      {isMobile && isSearching && (<div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ flex: '1', marginBottom: '10px'}}>
          <button id="clearSearchButton" className="search-button" onClick={() => { handleClearSearch(); }}>Clear Search</button>
        </div>
      </div>)}

      {!isScanning ? (
        <div>
          <div style={{ textAlign: 'center', justifyContent: 'space-between',  paddingTop: '0px' }}>
            <div style={{ textAlign: 'center'}}>
              {showFilters && (
                  <div>
                    <button className='search-button-3' onClick={() => setShowShareModal(true)}><i class="fa-solid fa-people-group"></i></button>

                    <div className="select-container">
                      <label className='select-label'>Sort by:</label>
                      <select value={sortBy} onChange={(e) => setSortBy(e.target.value)} className='sort-by-2'>
                        <option value="added">Added</option>
                        <option value="title">Title</option>
                        <option value="author">Author</option>
                      </select>
                    </div>

                    <div className="select-container">
                      <label className="select-label">Read State:</label>
                      <select className="sort-by-2" value={readStateFilter} onChange={(e) => setReadStateFilter(e.target.value)}>
                        <option value="">All</option>
                        <option value="READ">READ</option>
                        <option value="UNREAD">UNREAD</option>
                        <option value="READING">READING</option>
                      </select>
                    </div>

                    <div className="select-container">
                      <label className="select-label">Ownership:</label>
                      <select className="sort-by-2" value={ownershipStateFilter} onChange={(e) => setOwnershipStateFilter(e.target.value)}>
                        <option value="">All</option>
                        <option value="OWNED">OWNED</option>
                        <option value="ORDERED">ORDERED</option>
                        <option value="WANTED">WANTED</option>
                      </select>
                    </div>

                    { (sharedWith.length > 0 && sharedWith[sharedWith.length-1].name.length > 0) && (
                      <div className="select-container">
                        <label className="select-label">Owner:</label>
                        <select className="sort-by-2" value={ownerStateFilter} onChange={(e) => setOwnerStateFilter(e.target.value)}>
                          <option value="">All</option>
                          <option value={localStorage.getItem('loggedInUsername')}>{localStorage.getItem('loggedInUsername')}</option>
                          {sharedWith.map((user, index) => (
                            <option value={user.name} key={index}>{user.name}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>
          
          <div className="pagination">
            {renderPageNumbers()}
          </div>

          {loading && (
            <div className="loading-container">
              <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
            </div>
          )} {/* Display loading message when loading is true */}

          <table className="custom-table">
            <tbody>
              <tr>
                {currentBooks.map((book, index) => {
                  // Parse the imageLinks string into a JSON object
                  const imageLinks = JSON.parse(book.imageLinks);

                  return (
                    <td className={book.special ? "special-book-item" : "book-item"} key={index}>
                      <div onClick={() => handleBookClick(book)}>
                        { (book.use_custom_image && book.uploaded_image_b64 != "") ? (
                          <img className="book-thumbnail" src={book.uploaded_image_b64} alt={book.title} />
                        ) : imageLinks && imageLinks.thumbnail ? (
                          <img className="book-thumbnail" src={imageLinks.thumbnail} alt={book.title} />
                        ) : null}
                        <div>
                          <p>{book.title} </p>
                          <p>
                            <span style={{ 
                                backgroundColor: getBackgroundColor(book.ownership),
                                padding: '1px 10px',
                                borderRadius: '5px'
                              }}>
                                {book.ownership}
                              </span>
                          </p>
                          <p>
                            <span style={{ 
                                backgroundColor: getBackgroundColor(book.read),
                                padding: '1px 10px',
                                borderRadius: '5px'
                              }}>
                                {book.read}
                              </span>
                          </p>                  

                        </div>
                      </div>
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>

          <div className="pagination">
            {renderPageNumbers()}
          </div>


          {(selectedBook) && (
            <div className="book-details-modal">
              <div className="modal-content">
                <h2 className="model-h2">
                  {selectedBook.title}

                  {selectedBook.owner === localStorage.getItem('loggedInUsername') && (
                    <>
                      <button className="search-button-3 black" onClick={handleEditClick}><i class="fa-solid fa-pen-to-square"></i></button>
                      <button className='search-button-3 black' onClick={() => { deleteBook(selectedBook.id) }}><i class="fa-solid fa-book-skull"></i></button>
                    </>
                  )}
                </h2>
                <div className="book-description-container">
                  { (selectedBook.use_custom_image && selectedBook.uploaded_image_b64 != "") ? (
                    <img className="book-thumbnail" src={selectedBook.uploaded_image_b64} alt={selectedBook.title} />
                  ) : selectedBook.imageLinks != "{}" ? (
                    <img className="book-thumbnail" src={JSON.parse(selectedBook.imageLinks).thumbnail} alt={selectedBook.title} />
                  ) : null}
                  <div className="book-description">
                    <p>{selectedBook.description}</p>
                  </div>
                </div>
                <div className="book-details">
                  <p>ISBN: {selectedBook.isbn}</p>
                  <p>Format:
                    <button className='search-button' onClick={() => {setFormat(!selectedBook.format); handleFormatClick(selectedBook);}}>
                      {selectedBook.format ? 'Digital' : 'Physical'}
                    </button>
                  </p>
                  <p>Edition:
                    <button className='search-button' onClick={() => {setSpecial(!selectedBook.special); handleSpecialClick(selectedBook);}}>
                      {selectedBook.special ? 'Special' : 'Standard'}
                    </button>
                  </p>
                  <p>Publisher: {selectedBook.publisher}</p>
                  <p>Published Date: {selectedBook.publishedDate}</p>
                  <p>Author: {selectedBook.author}</p>
                  <p>Added: {convertTimestampToDate(selectedBook.timestamp)}</p>
                  <p>Added By: {selectedBook.owner}</p>
                </div>
                
                { collections.length > 0 && <div>
                  <select className="select-box-modal" value={selectedCollectionId} onChange={handleCollectionSelect}>
                    {collections.map((collection) => (
                      <option key={collection.id} value={collection.id}>{collection.name}</option>
                    ))}
                  </select>
                  <button className="search-button" onClick={handleAddToCollection}>Add to Collection</button>
                  {(setAddToCollectionMessage && addToCollectionMessage.length > 0) && (
                    <p>{addToCollectionMessage}</p>
                  )}  

                </div>}

                { bookCollections.length > 0 && <div>
                  <select className="select-box-modal">
                    {bookCollections.map((collection) => (
                      <option>{collection}</option>
                    ))}
                  </select>

                </div>}

                <div>
                  <select className='select-box-modal' value={selectedBook.read} onChange={handleReadStateChange}>
                    <option value={READ_STATE[2]}>READING</option>
                    <option value={READ_STATE[1]}>UNREAD</option>
                    <option value={READ_STATE[0]}>READ</option>
                  </select>
                  <select className="select-box-modal" value={selectedBook.ownership} onChange={handleOwnershipStateChange}>
                    <option value={OWNERSHIP_STATE[0]}>OWNED</option>
                    <option value={OWNERSHIP_STATE[1]}>ORDERED</option>
                    <option value={OWNERSHIP_STATE[2]}>WANTED</option>
                  </select>
                </div>

                <div class="edit-modal-buttons">
                  <button className='search-button' onClick={handleCloseBookDetails}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
          {/* New modal for editing title */}
          {isEditModalOpen && (
            <div className="book-details-modal">
              <div className="modal-content">
                <h3>Edit Book</h3>
                <div class="input-group">
                  <label>Title:</label>
                  <input
                    id="title"
                    className='search-box-add'
                    type="text"
                    value={newTitle}
                    onChange={(e) => setNewTitle(e.target.value)}
                  />
                </div>
                <div class="input-group">
                  <label>Description:</label>
                  <input
                    id="description"
                    className='search-box-add'
                    value={newDescription}
                    onChange={(e) => setNewDescription(e.target.value)}
                  />
                </div>
                <div class="input-group">
                  <label>Custom Image:</label>
                  <button className='search-button-2' onClick={handleIsCustomImageToggled}>
                    { isCusomImageToggled ? "Yes" : "No"}
                  </button>
                </div>

                { isCusomImageToggled ? (<div className="input-group">
                  <label>Upload Photo:</label>
                  <input
                    id="image"
                    className="search-box-add"
                    type="file"
                    accept="image/*"
                    capture="camera" // Use the camera
                    onChange={handleImageUpload}
                  />
                </div>): null}

                <div className="edit-modal-buttons">
                  <button className='search-button' onClick={handleSaveTitle}>Save</button>
                  <button className='search-button' onClick={handleCloseEditModal}>Close</button>
                </div>
              </div>
            </div>
          )}

          {/* Share Modal */}
          {showShareModal && (
            <div className="book-details-modal">
              <div className="modal-content">
                <h2>Sharing</h2>
                {/* Display shared with */}
                  { sharedWith.length > 0 && sharedWith[sharedWith.length-1].name.length > 0 ? (<div>

                    <h3>Shared With:</h3>
                    {sharedWith.map((user, index) => (
                      <ul key={index}>
                        <div>
                          <label className='label' key={index}>{user.name}</label>
                          <button className='search-button-3 black' onClick={() => unshareUser(user.name)}><i class="fa-solid fa-user-xmark"></i></button>
                        </div>
                      </ul>
                    ))}
                    </div>) : <div> Not Sharing with Anyone. </div>
                    
                    }
                
                {/* Share with new user */}
                <div>
                  <h3>Share with New User:</h3>
                  <input className='search-box-add' type="text" value={newUser} onChange={(e) => setNewUser(e.target.value)} />
                  <div className="edit-modal-buttons">
                    <button className='search-button' onClick={shareBooks}>Share</button>
                    <button className='search-button' onClick={() => setShowShareModal(false)}>Close</button>
                  </div>
                </div>
              </div>
            </div>
          )}


        </div>
      ) : (
        <div>
          <Scan />
        </div>
      )
    }
    </div>
  );
}

export default Library;
