import React, { useState, useEffect } from 'react';
import '../css/search.css'; // Import the CSS file for styling

function Search() {
  const [books, setBooks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [booksPerPage] = useState(102); // Set the number of books per page
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false); // Add state for search button text
  const [loading, setLoading] = useState(false); // Introduce loading state

  const isMobileDevice = () => {
    return window.innerWidth <= 768; // You can adjust the width as per your requirement
  }
  const [isMobile, setIsMobile] = useState(isMobileDevice());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileDevice());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const READ_STATE = {
    0: "READ",
    1: "UNREAD",
    2: "READING"
  };
  
  const OWNERSHIP_STATE = {
    0: "OWNED",
    1: "ORDERED",
    2: "WANTED"
  };

  const handleSearch = async () => {
    setLoading(true);
    const token = localStorage.getItem('accessToken');
    const user = localStorage.getItem('loggedInUsername');

    try {
      const response = await fetch("/library/google", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ q: searchTerm, owner: user }),
      });
      if (response.ok) {
        const data = await response.json();
        setBooks(data);
      } else {
        console.error('Search failed');
      }
       
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClearSearch = async() => {
    setIsSearching(false);
    setSearchTerm('');
    setBooks([]);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      document.getElementById('searchButton').click();
    }
  };

  const addBookToLibrary = async (book, index) => {
    const user = localStorage.getItem('loggedInUsername');
    const token = localStorage.getItem('accessToken');

    try {
      const response = await fetch("/library/add-searched-book", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
          user: user,
          book: book,
          readState: READ_STATE[1],
          ownershipState: OWNERSHIP_STATE[0],
          timestamp: Math.floor(Date.now() / 1000),
          format: 0
        }),
      });
      if (response.ok) {
        const data = await response.json();
        setAddButtonText(index, "Added", "green");
      } else {
        console.error('Search failed');
      }
       
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const setAddButtonText = (index, text, color) => {
    document.getElementById(index).textContent = text;
    document.getElementById(index).style.backgroundColor = color;
  };


  return (
    <div className='lib-page'>
      <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between',  paddingTop: '10px' }}>
        <div style={{ flex: '1', marginLeft: '15px' }}>
          <input
            id="search-box"
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown} // Add key press event listener
            className='search-box'
          />
          <button id="searchButton" className="search-button" onClick={() => { setCurrentPage(1); setIsSearching(true); handleSearch(); }}>Search</button>
          { isSearching && <button id="clearSearchButton" className="search-button" onClick={() => { handleClearSearch(); }}>Clear Search</button>}
        </div>
      </div>

      
      <div className="pagination">
      </div>

      {loading && (
        <div className="loading-container">
          <p>Loading Books...</p>
        </div>
      )} {/* Display loading message when loading is true */}

      <table className="search-table">
        <tbody>
          <tr>
            {books.map((book, index) => {
              // Parse the imageLinks string into a JSON object
              const imageLinks = JSON.parse(book.imageLinks);
              const buttonClass = book.found ? 'green-background add-button' : 'add-button';  

              return (
                <td key={index}>
                  <div className='search-item'>
                    <div className='search-item-left'>
                      <img className="book-thumbnail" src={imageLinks.thumbnail} alt={book.title} />
                      <button id={index} className={buttonClass} onClick={() => { addBookToLibrary(book, index); }}>{book.found ? "Added" : "Add To Library"}</button>
                    </div>
                    {!isMobile ? (
                      <>
                        <div className='search-item-right'>
                        <div className="book-title">{book.title}</div>
                        <div className="book-author">{book.author}</div>
                        {book.description.length > 0 && (<div className="book-description">{book.description}</div>)}
                        <div className="book-isbn">{book.isbn}</div>
                        <div className="book-publishedDate">{book.publishedDate}</div>
                        </div>
                        <div className='search-item-left'>
                        </div>
                      </>
                  ) : (
                    <>
                      <div className="book-title">{book.title}</div>
                      <div className="book-author">{book.author}</div>
                    </>)}
                  </div>
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    </div>
  );
} 


export default Search;
