import ReactDom from "react-dom";
import * as serviceWorker from './serviceWorker';
import DynamicComponentLoader from './components/DynamicComponentLoader.js'

import './css/index.css'

ReactDom.render((
  <div className="app">
    <DynamicComponentLoader /> 
  </div>
), document.getElementById("app"));

serviceWorker.register();
