import React, { useState, useEffect } from 'react';
import '../css/DynamicComponentLoader.css';
import '../css/login.css';

import Search from "./search";
import Library from "./library";
import Collections from "./collections";
import Profile from "./profile";

const DynamicComponentLoader = () => {
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [password3, setPassword3] = useState('');
  const [email, setEmail] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [loggedInUsername, setLoggedInUsername] = useState('');
  const [isForgotPassword, setIsForgetPassword] = useState(false);
  const [isForgotPasswordSent, setIsForgetPasswordSent] = useState(false);
  const [isForgetPasswordFlow, setIsForgetPasswordFlow] = useState(false);
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [token, setToken] = useState(false);
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleComponentChange = (component) => {
    if(sidebarOpen){
      setSidebarOpen(false);
    }
    setSelectedComponent(component);
  };

  const toggleLoginForm = () => {
    setShowLoginForm(!showLoginForm);
  };

  const toggleMode = () => {
    setIsLogin(!isLogin);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = isLogin ? "/auth/login" : "/auth/register";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password, email }),
      });
      if (response.ok) {
        const data = await response.json();
        if (isLogin) {
          setAccessToken(data.access_token);
          setLoggedInUsername(data.username);
          setIsLoggedIn(true);
          setShowLoginForm(false);
          handleComponentChange('LibraryComponent'); // Load the library component

          // Save access token and username to local storage
          localStorage.setItem('isLoggedIn', true);
          localStorage.setItem('accessToken', data.access_token);
          localStorage.setItem('loggedInUsername', data.username);
        } else if (response.status === 201) {
          // Registration successful, prompt user to login
          setIsLogin(true); // Switch to login mode
          setIsLoggedIn(false);
          setShowLoginForm(true);
        }
      } else {
        console.error('Login or registration failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
    setUsername('');
    setPassword('');
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setSidebarOpen(false);
    // Remove items from local storage on logout
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('loggedInUsername');
    setAccessToken(null);
    setLoggedInUsername('');
    setSelectedComponent(null); // Reset selected component
    setShowLoginForm(true);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    try {
      const response = fetch("/auth/reset_password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      setEmail('');
      setIsForgetPassword(false);
      setIsForgetPasswordSent(true);
  
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const handlePasswordReset = async (password, confirm) => {
    if(password !== confirm){
      setPasswordNotMatch(true);
    }else{
      try {
        const response = await fetch("/auth/reset_with_token", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token, password }),
        });
        if (response.ok) {
          const data = await response.json();
          setTokenIsValid(false);
          setIsForgetPassword(false);
          setIsForgetPasswordFlow(false);
          setIsLogin(true);

          const newUrl = window.location.protocol + "//" + window.location.host;
          window.history.replaceState({path: newUrl}, '', newUrl);

        } else {
          console.error('Password Reset Failure');
        }
      } catch (error) {
        console.error('Error:', error);
      }  
    }
  }

  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case 'LibraryComponent':
        return <Library />;
      case 'Search':
        return <Search />;
      case 'Collections':
        return <Collections />;
      case 'Profile':
        return <Profile />;
      default:
        return null;
    }
  };

  const checkTokenIsValid = async (token) => {
    try {
      const response = await fetch("/auth/check_token_valid", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      });
      if (response.ok) {
        const data = await response.json();
        if(data.valid){
          setTokenIsValid(true);
          setToken(token)
          console.log("VALID")
        }else{
          setTokenIsValid(false);
        }
      } else {
        console.error('Login or registration failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }

  }

  function extractTokenFromUrl() {
    const params = new URLSearchParams(window.location.search);
    return params.get('passwordreset');
  }

  useEffect(() => {

    const token = extractTokenFromUrl();
    if(token != null){
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('loggedInUsername');
      setAccessToken(null);
      setLoggedInUsername('');
      setIsForgetPasswordFlow(true);
      checkTokenIsValid(token);
    }

    const loggedInStatus = localStorage.getItem('isLoggedIn');
    if (loggedInStatus === 'true') {
      setIsLoggedIn(true);
      const savedAccessToken = localStorage.getItem('accessToken');
      const savedUsername = localStorage.getItem('loggedInUsername');
      if (savedAccessToken && savedUsername) {
        setShowLoginForm(false);
        setAccessToken(savedAccessToken);
        setLoggedInUsername(savedUsername);
        handleComponentChange('LibraryComponent'); // Load the library component
      }
    } else {
      // Redirect to login/register page or show the login form
      setSidebarOpen(true);
      setShowLoginForm(true);
    }
  }, []);

  const handlePasswordResetSent = () => {
    setIsForgetPasswordSent(false); setIsLogin(true);
  }
  
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };


  return (
    <div className="page-container">
      <header className="header">
        <div className="title-container">
          <h1>Manderley Library</h1>
        </div>
        <button className="sidebar-toggle-button" onClick={toggleSidebar}>
          ☰
        </button>
        <div className={`sidebar-overlay ${sidebarOpen ? 'open' : ''}`} onClick={closeSidebar}></div>
        <nav className={`sidebar ${sidebarOpen ? 'open' : ''}`} onClick={(e) => e.stopPropagation()}>
        <div>
            <button className="nav-button" onClick={() => handleComponentChange('LibraryComponent')} disabled={!isLoggedIn}>
            <i className="fas fa-book"></i> Library 
            </button>
          </div>
          
          <div>
            <button className="nav-button" onClick={() => handleComponentChange('Search')} disabled={!isLoggedIn}>
              <i className="fas fa-search"></i> Search
            </button>
          </div>
          
          {isLoggedIn && (
            <>
              <div>
                <button className="nav-button" onClick={() => handleComponentChange('Collections')}>
                  <i className="fas fa-layer-group"></i> Collections 
                </button>
              </div>
            </>
          )}
          {isLoggedIn ? (
            <>
              <div>
                <button className="profile-button" onClick={() => handleComponentChange('Profile')}>
                  <i className="fas fa-user"></i>{loggedInUsername} 
                </button>
              </div>
              <div>
                <button className="logout-button" onClick={handleLogout}>
                <i className="fas fa-sign-out-alt"></i> Logout {loggedInUsername} 
                </button>
              </div>
            </>
          ) : (
            <button className="auth-button" onClick={toggleLoginForm}>
              <i className="fas fa-sign-in-alt"></i> {isLogin ? 'Login' : 'Register'} 
            </button>
          )}
        </nav>
      </header>

      {renderSelectedComponent()}

      {showLoginForm && !isForgotPassword && !isForgetPasswordFlow && !isForgotPasswordSent && (
        <div className="login-form">
          <h2>{isLogin ? 'Login' : 'Register'}</h2>
          <form onSubmit={handleSubmit}>
            <label>
              <input placeholder="Username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
            </label>
            <label>
              <input placeholder="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </label>
            {!isLogin && (<label>
              <input placeholder="Email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
            </label>)}
            <div className="button-group">
              <button className="login-button" type="submit">{isLogin ? 'Login' : 'Register'}</button>
              <button className="login-button" type="button" onClick={toggleMode}>
                {isLogin ? 'Switch to Register' : 'Switch to Login'}
              </button>
            </div>
            <div className='button-group'>
              <button className="login-button" onClick={() => setIsForgetPassword(true)}>Forgot Password?</button>
            </div>
          </form>
        </div>
      )}

      {isForgotPassword && !isForgetPasswordFlow && (
        <div className="login-form">
          <form onSubmit={handleForgotPassword}>
            <h2>Forget Password?</h2>
            <label>
              <input placeholder="Email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
            </label>
            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
              <button className="login-button" type='submit'>Request Password Reset Link</button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: '10px' }}>
              <button className="login-button" type="button" onClick={() => setIsForgetPassword(false)}>
                  Exit
              </button>
            </div>
          </form>
        </div>
      )}

      {isForgotPasswordSent && (
        <div className="login-form">
          <form onSubmit={handlePasswordResetSent}>
            <h2>Password Reset Link Sent!</h2>
            <p>You should receive a link to reset your password within 5 mins!</p>
            <div className='button-group'>
              <button className="login-button" type="submit">
                  Exit
              </button>
            </div>
          </form>
        </div>
      )}

      {isForgetPasswordFlow && !tokenIsValid && (
        <div className='login-form'>
          <h2>TOKEN NOT VALID</h2>
        </div>
      )}

      {isForgetPasswordFlow && tokenIsValid && !isLoggedIn && (
        <div className="login-form">
          <h2>Enter New Password</h2>
          <label>
            <input placeholder="New Password" type="password" value={password2} onChange={(e) => setPassword2(e.target.value)} />
          </label>
          <label>
            <input placeholder="Confirm New Password" type="password" value={password3} onChange={(e) => setPassword3(e.target.value)} />
          </label>
          <div className="button-group">
            <button className="login-button" onClick={() => handlePasswordReset(password2, password3)}>Reset Password</button>
          </div>
          <div className='button-group'>
            <button className="login-button" type="button" onClick={() => {setIsForgetPasswordFlow(false); setIsLogin(true)}}>
                Exit
            </button>
          </div>
        </div>
      )}

      {passwordNotMatch && (
        <div className='login-form'>
          <h2>Passwords do not match!</h2>
          <div className='button-group'>
            <button type="button" onClick={() => {setPasswordNotMatch(false); setPassword2(''); setPassword3('');}}>
                Exit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DynamicComponentLoader;
