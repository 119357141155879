import React, { useState, useEffect } from 'react';

import '../css/profile.css'; // Import the CSS file for styling

function Profile() {
  const [bookCount, setBookCount] = useState({ total_books: 0, read_books: 0, unread_books: 0 });
  const [email, setEmail] = useState('');
  const [changeEmail, setChangeEmail] = useState(false);
  const [newEmail, setNewEmail] = useState('');

  const getBookCount = async () => {
    const user = localStorage.getItem('loggedInUsername');
    const token = localStorage.getItem('accessToken');

    try {
      const response = await fetch("/library/books/count", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ user }),
      });
      if (response.ok) {
        const data = await response.json();
        setBookCount(data);
      } else {
        console.error('Failed to fetch collections');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getEmail = async () => {
    const user = localStorage.getItem('loggedInUsername');
    const token = localStorage.getItem('accessToken');

    try {
      const response = await fetch("/auth/email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ user }),
      });
      if (response.ok) {
        const data = await response.json();
        setEmail(data);
      } else {
        console.error('Failed to fetch collections');
      }
    } catch (error) {
      console.error('Error:', error);
    }

  }

  const handleChangeEmail = async() => {
    const user = localStorage.getItem('loggedInUsername');
    const token = localStorage.getItem('accessToken');

    try {
      const response = await fetch("/auth/change-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ user, newEmail }),
      });
      if (response.ok) {
        const data = await response.json();
        setChangeEmail(false);
        setEmail(newEmail);
        getEmail();
      } else {
        console.error('Failed to fetch collections');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    getBookCount();
    getEmail();
  }, []);

  return (
    <div className='lib-page'>
      <div className="container">
        <div className="column">
          <p><label className='label'>Total Books: {bookCount.total_books}</label></p>
          <p><label className='label'>Read Books: {bookCount.read_books} ({Math.ceil((bookCount.read_books / bookCount.total_books)*100)}%)</label></p>
          <p><label className='label'>Unread Books: {bookCount.unread_books} ({Math.floor((bookCount.unread_books / bookCount.total_books)*100)}%)</label></p>
          <p><label className='label'>Email: {email}</label></p>

          <button className='search-button' onClick={() => setChangeEmail(true)}>Change Email?</button>
        </div>

        {changeEmail && (
          <div className="login-form">
            <label>
              New Email: <input type="text" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
            </label>
            <div className='button-group'>
              <button onClick={() => {handleChangeEmail()}}>Change Email</button>
              <button onClick={() => {setChangeEmail(false)}}>Exit</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;
