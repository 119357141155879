import React, { useState, useEffect, cloneElement } from 'react';
import '../css/collections.css'; // Import the CSS file for styling

function Collections() {
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [sortBy, setSortBy] = useState('name'); // Set default value to 'title'
  const [searchTerm, setSearchTerm] = useState('');
  const [searchButtonText, setSearchButtonText] = useState('Search'); // Add state for search button text
  const [showAddModal, setShowAddModal] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState('');
  const [deleteClicked, setDeleteClicked] = useState(false); // State to track delete button click
  const [isSearching, setIsSearching] = useState(false); // Add state for search button text
  const [currentPage, setCurrentPage] = useState(1);
  const [collectionsPerPage] = useState(24); // Set the number of books per page
  const [loading, setLoading] = useState(false); // Introduce loading state
  const [showFilters, setShowFilters] = useState(false); // Introduce loading state


  const isMobileDevice = () => {
    return window.innerWidth <= 768; // You can adjust the width as per your requirement
  }
  const [isMobile, setIsMobile] = useState(isMobileDevice());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileDevice());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Pagination Logic
  const indexOfLastBook = currentPage * collectionsPerPage;
  const indexOfFirstBook = indexOfLastBook - collectionsPerPage;
  const currentCollections = collections.slice(indexOfFirstBook, indexOfLastBook);
  
  // Pagination Logic
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(collections.length / collectionsPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = () => {
    const maxVisiblePages = 3;
    const totalPages = pageNumbers.length;

    if (totalPages <= maxVisiblePages) {
      return pageNumbers.map(number => (
        <button
          key={number}
          onClick={() => setCurrentPage(number)}
          className={currentPage === number ? "active" : ""}
        >
          {number}
        </button>
      ));
    } else {
      let leftSide = [];
      let rightSide = [];

      if (currentPage <= maxVisiblePages - 1) {
        leftSide = pageNumbers.slice(0, maxVisiblePages);
        rightSide = ["right"];
      } else if (currentPage >= totalPages - maxVisiblePages + 1) {
        leftSide = ["left"];
        rightSide = pageNumbers.slice(totalPages - maxVisiblePages, totalPages);
      } else {
        const middleIndex = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 0);
        leftSide = ["left"];
        rightSide = ["right"];

        for (let i = middleIndex; i < middleIndex + maxVisiblePages; i++) {
          leftSide.push(i);
        }
      }

      return (
        <>
          {leftSide.map((number, index) => (
            <button
              key={index}
              onClick={() => {
                if (number !== "left" && number !== "right") {
                  setCurrentPage(number);
                } else if (number === "left") {
                  setCurrentPage(currentPage - 1);
                }
                window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page

              }}
              className={currentPage === number ? "active" : ""}
            >
              {number !== "left" && number !== "right" ? number : "←"}
            </button>
          ))}
          {rightSide.map((number, index) => (
            <button
              key={index + leftSide.length}
              onClick={() => {
                if (number !== "left" && number !== "right") {
                  setCurrentPage(number);
                } else if (number === "right") {
                  setCurrentPage(currentPage + 1);
                }
                window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
              }}
              className={currentPage === number ? "active" : ""}
            >
              {number !== "left" && number !== "right" ? number : "→"}
            </button>
          ))}
        </>
      );
    }
  };
  
  const getAllCollections = async () => {
    setLoading(true);

    const user = localStorage.getItem('loggedInUsername');
    const token = localStorage.getItem('accessToken');

    try {
      const response = await fetch("/library/collections", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ user, sortBy }),
      });
      if (response.ok) {
        const data = await response.json();
        setCollections(data);
      } else {
        console.error('Login or registration failed');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllCollections();
  }, [sortBy]);

  const handleSearch = async () => {
    const user = localStorage.getItem('loggedInUsername');
    const token = localStorage.getItem('accessToken');

    try {
      const response = await fetch("/library/collection/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ searchTerm, user }),
      });
      if (response.ok) {
        const data = await response.json();
        setCollections(data);
      } else {
        console.error('Search failed');
      }
       
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      document.getElementById('searchButton').click();
    }
  };

  const handleCollectionClick = async(collection) => {
    const user = localStorage.getItem('loggedInUsername');
    const token = localStorage.getItem('accessToken');

      try {
        const response = await fetch(`/library/collection/books`, {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({ user, collectionId: collection.id }),
        });
        if (response.ok) {
            const data = await response.json();
            setSelectedCollection({ ...collection, books: data.books });
        } else {
            console.error('Failed to fetch books for the collection');
        }
        } catch (error) {
        console.error('Error:', error);
        }
    };

  const handleCloseCollectionDetails = () => {
    setSelectedCollection(null);
    if(searchTerm.length > 0){
      handleSearch();
    }else{
      getAllCollections();
    }
  };

  const toggleSearchButtonText = () => {
    setSearchButtonText(searchButtonText === 'Search' ? 'Clear Search' : 'Search');
  };

  const handleAddModalClose = () => {
    setShowAddModal(false);
    setNewCollectionName('');
  };

  const READ_STATE = {
    0: "READ",
    1: "UNREAD",
    2: "READING"
  };
  
  const OWNERSHIP_STATE = {
    0: "OWNED",
    1: "ORDERED",
    2: "WANTED"
  };
  const getBackgroundColor = (value) => {
    switch(value) {
      case READ_STATE[0]:
        return '#459948';
      case READ_STATE[1]:
        return '#d83f92';
      case OWNERSHIP_STATE[0]:
        return '#d3d3e5';
      case OWNERSHIP_STATE[1]:
        return '#e398d0';
      case OWNERSHIP_STATE[2]:
        return '#e6e684';
      default:
        return '#6fc3e5';
    }
  };

  const handleAddCollection = async () => {
    const user = localStorage.getItem('loggedInUsername');
    const token = localStorage.getItem('accessToken');

    try {
      const response = await fetch("/library/collections/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ name: newCollectionName,
                               owner: user,
                               timestamp: Math.floor(Date.now() / 1000) }),
      });
      if (response.ok) {
        // Refresh collections after adding
        getAllCollections();
        setShowAddModal(false);
        setNewCollectionName('');
      } else {
        console.error('Failed to add collection');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const deleteCollection = async (collectionId) => {
    setSelectedCollection(null);
    const token = localStorage.getItem('accessToken');
    const user = localStorage.getItem('loggedInUsername');

    try {
      const response = await fetch("/library/collections/delete", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({ collectionId, user }),
      });
      if (response.ok) {
        // Collection deleted successfully, update UI or perform any necessary actions
        getAllCollections(); // Refresh collections after deletion
      } else {
        console.error('Failed to delete collection');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const deleteBookFromCollection = async (collectionId, bookId) => {
    const token = localStorage.getItem('accessToken');
    const user = localStorage.getItem('loggedInUsername');

    try {
      const response = await fetch("/library/collection/delete", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`

        },
        body: JSON.stringify({ bookId, collectionId, user }),
      });
      if (response.ok) {
        setSelectedCollection(prevState => ({
          ...prevState,
          books: prevState.books.filter(book => book.id !== bookId)
        }));
      } else {
        console.error('Failed to delete book from collection');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleClearSearch = async() => {
    setIsSearching(false);
    setSearchTerm('');
    getAllCollections();
  }

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  }

  return (
    <div className='lib-page'>
      <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between',  paddingTop: '10px' }}>
        <div style={{ flex: '1', marginLeft: '15px' }}>
          <input
            id="search-box"
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown} // Add key press event listener
            className='search-box'
          />
          <button id="searchButton" className="search-button-3" onClick={() => { setCurrentPage(1); setIsSearching(true); handleSearch(); }}><i className="fas fa-search"></i></button>
          <button className='search-button-3' onClick={toggleFilters}>{showFilters ? <i class="fa-solid fa-filter-circle-xmark"></i> : <i class="fa-solid fa-filter"></i>}</button>
          {!isMobile && isSearching && (<button id="clearSearchButton" className="search-button" onClick={() => { handleClearSearch(); }}>Clear Search</button>)}
          <button className='search-button-3' onClick={() => setShowAddModal(true)}>{<i class="fa-solid fa-book-medical"></i>}</button>
        </div>
      </div>

      <div style={{ textAlign: 'center', justifyContent: 'space-between',  paddingTop: '0px' }}>
        <div style={{ textAlign: 'center'}}>
          {showFilters && (
            <div className="select-container">
              <label className='select-label'>Sort by:</label>
              <select value={sortBy} onChange={(e) => setSortBy(e.target.value)} className='sort-by-2'>
                <option value="name">Name</option>
                <option value="newest">Newest</option>
              </select>
            </div>
          )}
        </div>
      </div>

      {loading && (
        <div className="loading-container">
          <i class="fa-solid fa-spinner fa-spin fa-2xl"></i>
        </div>
      )} {/* Display loading message when loading is true */}


      <div className="pagination">
        {renderPageNumbers()}
      </div>


      <table className="custom-table">
        <tbody>
          <tr>
            {currentCollections.map((collection, index) => {
              // Parse the imageLinks string into a JSON object
              let imageLinks = ""
              const image = collection.image;
              if(collection.image[0] !== "d" && collection.image !== ""){
                imageLinks = JSON.parse(collection.image)
              }

              return (
                <td key={index}>
                  <div className="book-item" onClick={() => handleCollectionClick(collection)}>

                    { (imageLinks && imageLinks.thumbnail) ? (
                      <img className="book-thumbnail" src={imageLinks.thumbnail} />
                    ) : image != "" ? (
                      <img className="book-thumbnail" src={image} />
                    ) : null}

                    <p className='name'>{collection.name} </p>
                    <div  style={{ textAlign: 'center' }}>
                        <button className="search-button-3 black" onClick={() => { deleteCollection(collection.id); setDeleteClicked(true);} }><i class="fa-solid fa-book-skull"></i></button>
                    </div>
                  </div>
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>

      <div className="pagination">
        {renderPageNumbers()}
      </div>
        
      {(selectedCollection && !deleteClicked) && (
        <div className="book-details-modal">
            <div className="modal-content">
                <h2>{selectedCollection.name}</h2>
                <div className="books-container">
                    {selectedCollection.books.map((book, index) => (
                        <div key={index} className="book-item-collection">
                            <div className='margin_45_left_right'>
                            {book.imageLinks != "{}" && JSON.parse(book.imageLinks) ? (
                              <img className="book-thumbnail" src={JSON.parse(book.imageLinks).thumbnail} alt={book.title} />
                            ) : book.uploaded_image_b64 != "" ? (
                              <img className="book-thumbnail" src={book.uploaded_image_b64} alt={book.title} />
                            ) : <img className="book-thumbnail" src={JSON.parse(book.imageLinks).thumbnail} alt={book.title} />}
                            </div>
                            <div className='margin_45_left_right'>
                              <p className="book-title-collection">{book.title}</p>
                              <div style={{ 
                                  backgroundColor: getBackgroundColor(book.read),
                                  padding: '1px 10px',
                                  borderRadius: '5px'
                                }}>
                                {book.read}
                              </div>
                              <div style={{ 
                                  backgroundColor: getBackgroundColor(book.ownership),
                                  padding: '1px 10px',
                                  borderRadius: '5px',
                                  marginTop: '5px'
                                }}>
                                {book.ownership}
                              </div>

                            </div>
                            
                            <div className='margin_45_left_right'>
                              <button className="delete-button" onClick={() => deleteBookFromCollection(selectedCollection.id, book.id)}>Delete</button>
                            </div>
                        </div>
                    ))}
                <button className="modal-button-add" onClick={handleCloseCollectionDetails}>
                    Close
                </button>
                </div>

            </div>
        </div>
    )}
      {showAddModal && (
        <div className="book-details-modal">
          <div className="modal-content">
            <h2>Add Collection</h2>
            
            <input
              type="text"
              className='search-box-add'
              placeholder="Collection Name"
              value={newCollectionName}
              onChange={(e) => setNewCollectionName(e.target.value)}
            />
            <button className="modal-button-add" onClick={handleAddCollection}>Add Collection</button>
            <button className="modal-button-add" onClick={handleAddModalClose}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Collections;
